import React, { useContext } from 'react';
import AppIcon from '../../UI/Controls/AppIcons';
import { formatCurrencyAmount, safeString } from '../../Base/Utils';
import AuthContext from '../../Auth/AuthContext';
import { useMediaQuery, useTheme } from '@material-ui/core';

const JournalEntryTable = ({ fromEntries, toEntries, handleDeleteAction, mapTransactionType }) => {
    const authContext = useContext(AuthContext);
    const currencyCode = authContext?.currentSubscriber?.currencyCode || '';
    const theme = useTheme();
    const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

    const getTotalAmount = (entries) => {
        return entries.reduce((sum, entry) => sum + entry.amount, 0);
    };

    return (
        <div style={{ marginTop: '16px', display: 'flex', flexDirection: mobileDevice ? 'column' : 'row', overflowX: 'auto' }}>
            {/* Debit Table */}
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', borderLeft: "1px solid #ddd", borderBottom: mobileDevice ? "1px solid #ddd" : "none" }}>
                <div style={{ flexGrow: 1, overflowY: 'auto' }}>
                    <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                        <thead>
                            <tr>
                                <th colSpan={7} style={{ padding: '4px', textAlign: 'center', border: '1px solid #ddd' }}>Debit</th>
                            </tr>
                            <tr style={{ background: "rgb(148 200 251)" }}>
                                <th style={{ padding: '4px', textAlign: 'center', border: '1px solid #ddd' }}>SI.</th>
                                <th style={{ padding: '4px', textAlign: 'center', border: '1px solid #ddd' }}>Tran Type</th>
                                <th style={{ padding: '4px', textAlign: 'center', border: '1px solid #ddd' }}>Narrative</th>
                                <th style={{ padding: '4px', textAlign: 'center', border: '1px solid #ddd' }}>A/C No</th>
                                <th style={{ padding: '4px', minWidth: '100px', textAlign: 'center', border: '1px solid #ddd' }}>Amount</th>
                                <th style={{ padding: '4px', textAlign: 'center', border: '1px solid #ddd' }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {fromEntries?.map((fromEntry, fromIndex) => (
                                <tr key={`fromEntry-${fromIndex}`}>
                                    <td style={{ padding: '4px', textAlign: 'center', border: '1px solid #ddd' }}>{fromIndex + 1}</td>
                                    <td style={{ padding: '4px', textAlign: 'center', border: '1px solid #ddd' }}>{mapTransactionType(fromEntry?.transactionType)}</td>
                                    <td style={{ padding: '4px', textAlign: 'center', border: '1px solid #ddd' }}>{fromEntry?.narrative}</td>
                                    <td style={{ padding: '4px', textAlign: 'center', border: '1px solid #ddd' }}>{fromEntry?.accountNumber !== "" ? fromEntry?.accountNumber : safeString(fromEntry?.referenceName)}</td>
                                    <td style={{ padding: '4px', textAlign: 'right', border: '1px solid #ddd' }}>{fromEntry?.amount}</td>
                                    <td style={{ padding: '4px', textAlign: 'center', border: '1px solid #ddd' }}>
                                        <span onClick={() => handleDeleteAction(fromIndex, "Debit")} style={{ color: "red" }}>
                                            <AppIcon name="Delete" size="small" />
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                {/* From Entries Total */}
                <div style={{ marginTop: 'auto' }}>
                    <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                        <tfoot>
                            <tr>
                                <td colSpan="4" style={{ padding: '4px', textAlign: 'right', border: '1px solid #ddd', fontWeight: "bold" }}>Total</td>
                                <td style={{ padding: '4px', textAlign: 'right', border: '1px solid #ddd', fontWeight: "bold" }}>
                                    {formatCurrencyAmount(currencyCode, getTotalAmount(fromEntries), "green", true)}
                                </td>
                                <td style={{ padding: '4px', textAlign: 'center', border: '1px solid #ddd' }}></td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>

            {/* Credit Table */}
            <div style={{ flex: 1, display: 'flex', flexDirection: 'column', borderRight: "1px solid #ddd" }}>
                <div style={{ flexGrow: 1, overflowY: 'auto' }}>
                    <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                        <thead>
                            <tr>
                                <th colSpan={7} style={{ padding: '4px', textAlign: 'center', border: '1px solid #ddd' }}>Credit</th>
                            </tr>
                            <tr style={{ background: "rgb(148 200 251)" }}>
                                <th style={{ padding: '4px', textAlign: 'center', border: '1px solid #ddd' }}>SI.</th>
                                <th style={{ padding: '4px', textAlign: 'center', border: '1px solid #ddd' }}>Tran Type</th>
                                <th style={{ padding: '4px', textAlign: 'center', border: '1px solid #ddd' }}>Narrative</th>
                                <th style={{ padding: '4px', textAlign: 'center', border: '1px solid #ddd' }}>A/C No</th>
                                <th style={{ padding: '4px', minWidth: '100px', textAlign: 'center', border: '1px solid #ddd' }}>Amount</th>
                                <th style={{ padding: '4px', textAlign: 'center', border: '1px solid #ddd' }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {toEntries?.map((toEntry, toIndex) => (
                                <tr key={`toEntry-${toIndex}`}>
                                    <td style={{ padding: '4px', textAlign: 'center', border: '1px solid #ddd' }}>{toIndex + 1}</td>
                                    <td style={{ padding: '4px', textAlign: 'center', border: '1px solid #ddd' }}>{mapTransactionType(toEntry?.transactionType)}</td>
                                    <td style={{ padding: '4px', textAlign: 'center', border: '1px solid #ddd' }}>{toEntry?.narrative}</td>
                                    <td style={{ padding: '4px', textAlign: 'center', border: '1px solid #ddd' }}>{toEntry?.accountNumber !== "" ? toEntry?.accountNumber : safeString(toEntry?.referenceName)}</td>
                                    <td style={{ padding: '4px', textAlign: 'right', border: '1px solid #ddd' }}>{toEntry?.amount}</td>
                                    <td style={{ padding: '4px', textAlign: 'center', border: '1px solid #ddd' }}>
                                        <span onClick={() => handleDeleteAction(toIndex, "Credit")} style={{ color: "red" }}>
                                            <AppIcon name="Delete" size="small" />
                                        </span>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                {/* To Entries Total */}
                <div style={{ marginTop: 'auto' }}>
                    <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                        <tfoot>
                            <tr>
                                <td colSpan="4" style={{ padding: '4px', textAlign: 'right', border: '1px solid #ddd', fontWeight: "bold" }}>Total</td>
                                <td style={{ padding: '4px', textAlign: 'right', border: '1px solid #ddd', fontWeight: "bold" }}>
                                    {formatCurrencyAmount(currencyCode, getTotalAmount(toEntries), "green", true)}
                                </td>
                                <td style={{ padding: '4px', textAlign: 'center', border: '1px solid #ddd' }}></td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    );
};



export default JournalEntryTable;

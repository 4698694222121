import React, { useContext, useEffect, useState } from 'react';
import SelectParty from '../UI/Controls/Business/SelectParty';
import { getPartyList } from '../Business/Party/PartyBusiness';
import AuthContext from '../Auth/AuthContext';
import useStore from '../Base/Store';
import { isNullOrUndefined, safeInt, safeString } from '../Base/Utils';
import { EXPENSE_ENTITY, GL_ENTITY } from '../Base/Common';
import { getData } from '../Base/DataProvider';
import { SelectInput } from '../UI/Controls/InputFields';
import axios from 'axios';
import WebAPI from '../Base/WebAPI';

export default function PartiesJSX({ refrenceType, setFieldValue }) {
    const authContext = useContext(AuthContext);
    const [partiesData, setPartiesData] = useState([]);
    const [selectedParty, setSelectedParty] = useState(null);
    const showAlert = useStore(state => state.showAlert);
    const [glAccountList, setGLAccountList] = useState(null);
    const [expenseList, setExpenseList] = useState(null);
    const [incomeList, setIncomeList] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const mappedRefType = mapRefType(refrenceType);
                if (mappedRefType) {
                    const partiesResponse = await getPartyList(mappedRefType, authContext, showAlert);
                    if (!isNullOrUndefined(partiesResponse)) {
                        setPartiesData(partiesResponse);
                    }
                }

                if (mappedRefType) {
                    let token = null;
                    const response = await getData(GL_ENTITY, "/GLAccountList", authContext, token);
                    if (!isNullOrUndefined(response) && safeInt(response.code) > 0) {
                        showAlert(response.error, 'error');
                    } else {
                        setGLAccountList(response);
                    }
                }
                if (mappedRefType === "Expense") {
                    let source = axios.CancelToken.source();
                    const responseExpenseTypes = await WebAPI.get('/Expenses/GetExpensesTypesList/' + authContext.currentSubscriber.id, { headers: { "AccessToken": authContext.token }, cancelToken: source.token });
                    if (!isNullOrUndefined(responseExpenseTypes) && safeInt(responseExpenseTypes.code) > 0) {
                        showAlert(responseExpenseTypes.error, 'error');
                    } else {
                        setExpenseList(responseExpenseTypes.data);
                    }
                }

                if (mappedRefType === "Income") {
                    let source = axios.CancelToken.source();
                    const responseIncomeTypes = await WebAPI.get('/Income/GetIncomeTypeList/' + authContext.currentSubscriber.id, { headers: { "AccessToken": authContext.token }, cancelToken: source.token });
                    if (!isNullOrUndefined(responseIncomeTypes) && safeInt(responseIncomeTypes.code) > 0) {
                        showAlert(responseIncomeTypes.error, 'error');
                    } else {
                        setIncomeList(responseIncomeTypes.data);
                    }
                }

            } catch (error) {
                console.error("Error fetching data:", error);
                showAlert("An error occurred while fetching data", "error");
            }
        };

        fetchData();
    }, [refrenceType]);

    const handlePartySelectionChange = (value) => {

        if (!isNullOrUndefined(value)) {
            setSelectedParty(value);
            setFieldValue(`referenceId`, safeInt(value?.id));
            setFieldValue(`referenceName`, refrenceType === "CC" ? safeString(value?.legalName) : safeString(value?.name));
        }
    };

    const mapRefType = (inputRefType) => {
        const refTypeMap = {
            'CC': 'Customer',
            'SS': 'Supplier',
            'JJ': 'Journal',
            'EE': 'Expense',
            'II': 'Income'
        };

        return refTypeMap[inputRefType];
    };

    let transactionJSX;
    switch (refrenceType) {
        case 'CC':
        case 'SS':
            transactionJSX = (
                <>
                    <SelectParty
                        name={`referenceId`}
                        parties={partiesData}
                        selectedParty={selectedParty}
                        referenceType={mapRefType(refrenceType)}
                        handleSelectionChanged={(event, newValue) => {
                            handlePartySelectionChange(newValue);
                        }}
                    />
                </>
            );
            break;
        case 'JJ':
            transactionJSX = (
                <>
                    <SelectInput
                        label={`Account`}
                        required
                        name={`accountNumber`}
                    >
                        <option value={""}></option> {/* Add empty option */}
                        {glAccountList?.map((item) => (
                            <option key={safeString(item.id)} value={item.accountNumber}>
                                {`${item.accountNumber} ── ${item.description}` || ""}
                            </option>
                        ))}
                    </SelectInput>
                </>
            );
            break;
        case 'EE':
            transactionJSX = (
                <>
                    <SelectInput
                        label={`Expense Type`}
                        required
                        name={`accountNumber`}
                    >
                        <option value={""}></option> {/* Add empty option */}
                        {expenseList?.map((item) => (
                            <option key={safeString(item.id)} value={item.accountNumber}>
                                {`${item.description}` || ""}
                            </option>
                        ))}
                    </SelectInput>
                </>
            );
            break;
        case 'II':
            transactionJSX = (
                <>
                    <SelectInput
                        label={`Income Type`}
                        required
                        name={`accountNumber`}
                    >
                        <option value={""}></option> {/* Add empty option */}
                        {incomeList?.map((item) => (
                            <option key={safeString(item.id)} value={item.accountNumber}>
                                {`${item.description}` || ""}
                            </option>
                        ))}
                    </SelectInput>
                </>
            );
            break;
        default:
            break;
    }

    return <>{transactionJSX}</>;
}

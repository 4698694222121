import React, { useContext, useEffect, useState } from 'react';
import JournalEntry from './JournalEntry';
import JournalEntryTable from './JournalEntryTable';
import { getFinancialStartDate, getISODate, getLocaleDate, getTodaysDate, isEmptyObjectArray, isNullOrEmpty, isNullOrUndefined, safeAmount, safeDate, safeInt, safeString } from '../../Base/Utils';
import WebAPI from '../../Base/WebAPI';
import axios from 'axios';
import AuthContext from '../../Auth/AuthContext';
import useStore from '../../Base/Store';
import shallow from 'zustand/shallow';
import { Button, Grid, Typography } from '@material-ui/core';
import { PageHeader } from '../../UI/Controls/Controls';
import AppIcon from '../../UI/Controls/AppIcons';
import { TRANSACTION_ENTITY } from '../../Base/Common';
import useStyles from '../../../styles';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const JournalEntries = () => {
    const [transactionMode, setTransactionMode] = useState('Debit'); // Default to Credit
    const [transactionType, setTransactionType] = useState([]);
    const classes = useStyles();
    const [bankList, setBankList] = useState(null);
    const history = useHistory();
    const [closeDialog, setDialogResponse] = useStore(state => [state.closeDialog, state.setDialogResponse], shallow);
    const [fromEntriesTableData, setFromEntriesTableData] = useState([]);
    const [toEntriesTableData, setToEntriesTableData] = useState([]);
    const showAlert = useStore(state => state.showAlert);
    const authContext = useContext(AuthContext);
    const [waiting, setWaiting] = useStore(state => [state.waiting, state.setWaiting], shallow);

    var firstDate = firstDate = fromEntriesTableData[0]?.valueDate || toEntriesTableData[0]?.valueDate || '';

    const pageInfo = {
        pageHeading: 'Add journal entry',
        pageIcon: 'Transaction',
        okButtonCaption: 'Save',
        okButtonIcon: '',
        cancelButtonCaption: 'Back',
        entityName: TRANSACTION_ENTITY
    }
    const [fieldValues, setFieldValues] = useState({
        "branchId": 0,
        "accountNumber": "",
        "transactionType": "",
        "narrative": "",
        "referenceId": 0,
        "referenceType": "",
        "referenceName": "",
        "amount": 0,
        "valueDate": firstDate.length > 0 ? getISODate(firstDate) : getTodaysDate(),
        "currencyCode": "",
        "bankId": 0
    });



    const populateData = async (source, token = null) => {
        // Populate all that is required during page load caled from useEffect init()
        try {
            var TransactionsTypeResponse = await WebAPI.get('/Transactions/GetTransactionTypeList', { headers: { "AccessToken": token } });
            if (!isNullOrUndefined(TransactionsTypeResponse)) {
                setTransactionType(TransactionsTypeResponse?.data);
            }

            var responseBankList = await WebAPI.get('/BankDetails/GetBankDetailsList/' + authContext.currentUser.subscriberId, { headers: { "AccessToken": authContext.token }, cancelToken: source.token });

            if (!isNullOrUndefined(responseBankList) && safeInt(responseBankList.code) > 0) {
                showAlert(responseBankList.error, 'error');
            } else {
                setBankList(responseBankList.data);
            }
        } catch (error) {
            showAlert('An error occurred while fetching employee details.' + error, 'error');
        } finally {
            setWaiting(false);
        }
    }

    const mapTransactionType = (transactionType) => {
        switch (transactionType) {
            case "CC":
                return 'Customer';
            case 'SS':
                return 'Supplier';
            case 'EE':
                return "Expense";
            case 'II':
                return 'Income'
            case 'JJ':
                return 'Journal'
            default:
                return '';
        }
    };

    const ValidatingDate = (values) => {
        const allEntries = [...fromEntriesTableData, ...toEntriesTableData];
        const isDateConsistent = allEntries.every(entry => entry.valueDate === values.valueDate);

        if (!isDateConsistent) {
            showAlert(`${safeString(values?.referenceName)} Date can't be different; it should be the same across all entries. Cannot add.`, "error");
            return false;
        }

        return true;
    };


    const submitFormData = (values, resetForm, setFieldValue) => {
        try {
            let fromTempTableData = [...fromEntriesTableData];
            let toTempTableData = [...toEntriesTableData];
            if (values.transactionType === "CC" || values.transactionType === "SS") {
                if (values.referenceId === 0) {
                    showAlert(`${mapTransactionType(values.transactionType)} cannot be empty for this transaction type.`, "error");
                    return;
                }
            }
            if (values.transactionType === "JJ" || values.transactionType === "EE" || transactionType === "II") {
                if (values.accountNumber === "") {
                    showAlert(`Account number cannot be empty for this ${mapTransactionType(values.transactionType)} .`, "error");
                    return;
                }
            }

            if ((safeString(values.transactionType) === "JJ" || safeString(values.transactionType) === "EE" || safeString(values.transactionType) === "II")) {
                const isAccountNumberInFromEntries = fromTempTableData.some(entry => entry.accountNumber === values.accountNumber);
                const isAccountNumberInToEntries = toTempTableData.some(entry => entry.accountNumber === values.accountNumber);

                if (isAccountNumberInFromEntries) {
                    showAlert(` This Account number ${values.accountNumber} is already exists in Debit Entries.`, "error");
                    return;
                }

                if (isAccountNumberInToEntries) {
                    showAlert(`This Account number ${values.accountNumber} already exists in Credit Entries.`, "error");
                    return;
                }
            }

            if ((safeString(values.transactionType) === "SS" || safeString(values.transactionType) === "CC")) {
                const isPartyInFromEntries = fromTempTableData.some(entry => safeInt(entry.referenceId) === safeInt(values.referenceId));
                const isPartyInToEntries = toTempTableData.some(entry => safeInt(entry.referenceId) === safeInt(values.referenceId));

                if (isPartyInFromEntries) {
                    showAlert(`${safeString(values?.referenceName)} already exists in Debit Entries. Cannot add.`, "error");
                    return;
                }

                if (isPartyInToEntries) {
                    showAlert(`${safeString(values?.referenceName)} already exists in Credit Entries. Cannot add.`, "error");
                    return;
                }
            }

            if (!ValidatingDate(values)) {
                return;
            }

            if (transactionMode === "Debit") {
                fromTempTableData.push({
                    // From side
                    "branchId": safeInt(values?.branchId),
                    "accountNumber": safeString(values.accountNumber),
                    "transactionType": safeString(values.transactionType),
                    "narrative": safeString(values.narrative),
                    "referenceId": safeInt(values.referenceId),
                    "referenceType": safeString(values.referenceType),
                    "amount": safeAmount(values.amount),
                    "referenceName": safeString(values.referenceName),
                    "valueDate": values.valueDate,
                    "currencyCode": safeString(values.currencyCode),
                    "bankId": safeString(values.bankId),
                })
            } else {
                toTempTableData.push({
                    // To side
                    "branchId": safeInt(values?.branchId),
                    "accountNumber": safeString(values.accountNumber),
                    "transactionType": safeString(values.transactionType),
                    "narrative": safeString(values.narrative),
                    "referenceId": safeInt(values.referenceId),
                    "referenceType": safeString(values.referenceType),
                    "amount": safeAmount(values.amount),
                    "referenceName": safeString(values.referenceName),
                    "valueDate": values.valueDate,
                    "currencyCode": safeString(values.currencyCode),
                    "bankId": safeString(values.bankId),
                })
            }
            setFromEntriesTableData(fromTempTableData);
            setToEntriesTableData(toTempTableData);

            if (resetForm) {
                resetForm(); // Call resetForm to clear the form
            }

        } catch (error) {
            console.log(error);
        } finally {
            setWaiting(false);
        }

    }



    const buildSaveRequest = () => {
        let fromEntries = [];
        let toEntries = [];

        if (!isEmptyObjectArray(fromEntriesTableData)) {
            for (let i = 0; i < fromEntriesTableData.length; i++) {
                const entry = fromEntriesTableData[i];
                fromEntries.push({
                    "branchId": safeInt(entry.branchId),
                    "accountNumber": safeString(entry.accountNumber),
                    "transactionType": safeString(entry.transactionType),
                    "narrative": safeString(entry.narrative),
                    "referenceId": safeInt(entry.referenceId),
                    "referenceType": mapTransactionType(entry.transactionType),
                    "amount": safeInt(entry.amount),
                    "valueDate": entry.valueDate,
                    "currencyCode": entry.currencyCode,
                    "bankId": safeInt(entry.bankId)
                })
            }
        }


        if (!isEmptyObjectArray(toEntriesTableData)) {
            for (let i = 0; i < toEntriesTableData.length; i++) {
                const entry = toEntriesTableData[i];
                toEntries.push({
                    "branchId": safeInt(entry.branchId),
                    "accountNumber": safeString(entry.accountNumber),
                    "transactionType": safeString(entry.transactionType),
                    "narrative": safeString(entry.narrative),
                    "referenceId": safeInt(entry.referenceId),
                    "referenceType": mapTransactionType(entry.transactionType),
                    "amount": safeInt(entry.amount),
                    "valueDate": entry.valueDate,
                    "currencyCode": entry.currencyCode,
                    "bankId": safeInt(entry.bankId)
                })
            }
        }

        const req = {
            fromEntries: fromEntries,
            toEntries: toEntries
        }

        return req;
    }
    const saveJournalEntries = async () => {
        const request = buildSaveRequest();
        try {
            const apiUrl = "Transactions/CreateTransaction2";
            const response = await WebAPI.put(apiUrl, request, { headers: { "AccessToken": authContext.token } });

            if (!!response.data.code && response.data.code > 0) {
                showAlert(response.data.message, 'error');
            } else {
                showAlert('Transaction saved Successfully', 'success');
                history.push('/transactionList');
                setDialogResponse(response.data);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setWaiting(false);
        }
    }

    useEffect(() => {
        let source = axios.CancelToken.source();
        async function init() {
            await populateData(source.token);
        }
        init();
        return () => {
            source.cancel();
        };
    }, []);

    const handleDeleteEntries = (index, mode) => {
        if (mode === 'Debit') {
            const updatedFromEntries = fromEntriesTableData.filter((_, i) => i !== index);
            setFromEntriesTableData(updatedFromEntries);
        } else if (mode === 'Credit') {
            const updatedToEntries = toEntriesTableData.filter((_, i) => i !== index);
            setToEntriesTableData(updatedToEntries);
        }
    }


    return (
        <div>
            <Grid container alignContent="space-between">
                <Grid item xs={6} sm={6}>
                    <PageHeader heading={pageInfo.pageHeading}>
                        <AppIcon name={pageInfo.pageIcon} color="primary" />
                    </PageHeader>
                </Grid>
            </Grid>
            <JournalEntry
                handleAddEntry={submitFormData}
                fieldValues={fieldValues}
                transactionType={transactionType}
                bankList={bankList}
                transactionMode={transactionMode}
                setTransactionMode={setTransactionMode}
            />
            <Grid container item xs={12} className={classes.pullRight}>
                <Typography variant="caption1" style={{ display: 'flex', alignItems: 'center', fontWeight: 'bold', color: "blue", marginTop: '20px' }}>
                    <AppIcon name="Date" size="small" style={{ marginRight: '4px' }} />
                    {firstDate}
                </Typography>
            </Grid>
            <JournalEntryTable handleDeleteAction={handleDeleteEntries} fromEntries={fromEntriesTableData} toEntries={toEntriesTableData} mapTransactionType={mapTransactionType} />

            <Grid container spacing={1}>
                <Grid item xs={12} className={classes.pullRight}>
                    <Button
                        type="button"
                        disabled={fromEntriesTableData.length === 0 && toEntriesTableData.length === 0}
                        variant='contained'
                        className={classes.mt1}
                        color='primary'
                        onClick={saveJournalEntries}
                    >
                        Save Entries
                    </Button>

                </Grid>
            </Grid>
        </div>
    );
}

export default JournalEntries;

import { Field, Form, Formik } from 'formik';
import React from 'react';
import * as yup from 'yup';
import { getFinancialStartDate, getTodaysDate, isNullOrEmpty, safeString } from '../../Base/Utils';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import { DateInput, NumberInput, SelectInput, TextInput } from '../../UI/Controls/InputFields';
import useStyles from '../../../styles';
import PartiesJSX from '../PartiesJSX';

const JournalEntry = ({ fieldValues, handleAddEntry, transactionType, bankList, transactionMode, setTransactionMode, firstDate }) => {
    const classes = useStyles();
    return (
        <div>
            <Formik
                initialValues={fieldValues}
                enableReinitialize={true}
                onSubmit={async (values, { resetForm, setFieldValue }) => {
                    try {
                        await handleAddEntry(values, resetForm, setFieldValue);
                    } catch (error) {
                        console.error("Error adding entry:", error);
                    }
                }}
                validationSchema={yup.object({
                    amount: yup
                        .number()
                        .moreThan(0, 'Amount must be greater than 0') // Use moreThan instead of min
                        .required('Amount is required'),
                    valueDate: yup
                        .date()
                        .max(getTodaysDate(), 'Value Date should not be greater than today')
                        .min(getFinancialStartDate(), 'Value Date should not be less than the financial year start date')
                        .required('Value Date is required'),
                    transactionType: yup.string().required("required"),
                })}
            >
                {({ isSubmitting, setFieldValue, values }) => (
                    <>
                        <Form>
                            <Grid container className={classes.pt1} spacing={2} alignItems="center">
                                <Grid item xs={12} sm={4}>
                                    <FormControl required fullWidth variant="outlined" size="small">
                                        <InputLabel>Debit/Credit</InputLabel>
                                        <Field
                                            as={Select}
                                            name="transactionType"
                                            label="Debit/Credit"
                                            value={transactionMode}
                                            onChange={(event) => {
                                                setTransactionMode(event.target.value);
                                            }}
                                        >
                                            <MenuItem value="Credit">Credit</MenuItem>
                                            <MenuItem value="Debit">Debit</MenuItem>
                                        </Field>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <SelectInput label="Transaction Type" name="transactionType" required>
                                        <option value={""}></option>
                                        {transactionType.map((item, index) => (
                                            <option key={safeString(item.code)} value={safeString(item.code)}>
                                                {item.name}
                                            </option>
                                        ))}
                                    </SelectInput>
                                </Grid>
                                {
                                    !isNullOrEmpty(values.transactionType) &&
                                    <>
                                        <Grid item xs={12} sm={4}>
                                            <PartiesJSX
                                                refrenceType={values.transactionType}
                                                setFieldValue={setFieldValue}
                                            />
                                        </Grid>
                                    </>
                                }
                                {
                                    values?.accountNumber === "1010" &&
                                    <>
                                        <Grid item xs={12} sm={4}>
                                            <SelectInput
                                                label={'From bank'}
                                                required
                                                name={`bankId`}
                                            >
                                                <option>None</option>
                                                {bankList?.map((item, index) => (
                                                    <option key={safeString(item.id)} value={item.id}>
                                                        {item?.bankName}
                                                    </option>
                                                ))}
                                            </SelectInput>
                                        </Grid>
                                    </>
                                }
                                <Grid item xs={12} sm={4}>
                                    <NumberInput label="Amount" name="amount" placeholder="Amount" />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <TextInput label="Narrative" name="narrative" placeholder=""
                                        inputProps={{}} />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <DateInput label="Date" name="valueDate" placeholder="" required
                                        inputProps={{}} disable={firstDate} />
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Button type="submit" color="primary" variant='contained'>Add Entry</Button>
                                </Grid>
                            </Grid>
                        </Form>
                    </>
                )}
            </Formik>
        </div>
    );
}

export default JournalEntry;
